@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;

    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;

    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;

    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;

    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;

    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;

    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;

    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;

    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;

    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;

    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;

    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;

    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;

    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border font-inter;
  }

  body {
    @apply bg-background text-foreground;
  }

  label {
    @apply py-1;
  }
}

/* containerStyles */
@layer components {
  .minBodyContentContainer {
    @apply px-4 mx-auto w-full max-w-8xl flex-1;
  }

  .bodyContainer {
    @apply px-4 md:px-12 max-w-none grow-2 base:py-6 sm:py-10 md:py-10 base:px-4 sm:px-6 md:px-6 overflow-auto flex flex-col dark:bg-grayDark-700;
  }

  .mediumContainer {
    @apply px-0 mx-auto max-w-container.md;
  }

  .fullContainer {
    @apply px-0 mx-auto max-w-7xl;
  }

  .mainBodyContainer {
    @apply mx-auto px-4 max-w-8xl w-full flex-1;
  }

  /* tooltip arrow */
  .tooltipArrow {
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
  }

  .tooltipContent[data-side='top'] .tooltipArrow {
    clip-path: inset(0 -10px -10px -10px);
  }

  /* input groups */
  .input-group-base {
    @apply flex flex-row relative;
  }

  .input-group-left-element {
    @apply absolute left-0 top-1;
  }

  .input-group-right-element {
    @apply absolute right-0 top-1;
  }

  /* sidebar */
  .sidebar {
    @apply flex overflow-y-auto border-r border-solid h-full base:max-w-full sm:max-w-xs base:py-6 sm:py-8 base:px-4 sm:px-6 w-[15.5rem] bg-gray-50 border-r-gray-200 dark:bg-grayDark-800 dark:border-r-grayDark-600;
  }

  .all-initial>*:not(.cm-editor) {
    all: revert;
  }

  .all-initial * {
    @apply m-0 dark:caret-whiteAlpha-900;
  }

  .all-initial>*>p,
  .all-initial strong,
  .all-initial>*>span {
    @apply dark:text-whiteAlpha-900;
  }

  .all-initial a {
    @apply dark:text-primary;
  }

  div[class*='cm-gutters'],
  div[class*='cm-gutterElement'] {
    @apply dark:bg-transparent dark:text-whiteAlpha-900;
  }

  div[class*='cm-activeLine'] {
    @apply dark:bg-grayDark-700;
  }

  span[class*='sp-syntax-plain'],
  span[class*='sp-syntax-punctuation'],
  span[class*='sp-syntax-static'] {
    @apply dark:text-whiteAlpha-700;
  }

  .all-initial span[class*='_underline'] {
    @apply underline underline-offset-4;
  }

  /* modify tooltip to make it more visible */
  div[class*='_editorRoot']:not(.mdxeditor) {
    @apply absolute z-[100];
  }

  div[class*='_editorRoot'] div[class*='_tooltipContent'] {
    @apply bg-white p-1 px-2 rounded-md border border-solid border-input dark:bg-grayDark-800;
  }

  form[class*='_linkDialogEditForm'] div[class*='_formField'] {
    @apply flex w-full justify-between gap-4 mb-2;
  }

  div[class*='_formField'] input {
    @apply p-1 border border-solid border-input rounded-sm;
  }

  button[class*='_primaryButton'],
  button[class*='_secondaryButton'] {
    @apply mr-2 bg-white px-2 border border-solid border-input rounded-sm dark:bg-grayDark-800 dark:border-grayDark-600 dark:text-whiteAlpha-900;
  }

  button[class*='_primaryButton']:hover,
  button[class*='_secondaryButton']:hover {
    @apply text-primary;
  }

  div[class*='_linkDialogPopoverContent'] {
    @apply mr-2 bg-white px-4 py-2 border border-solid border-input rounded-sm flex items-center dark:bg-grayDark-800 dark:border-grayDark-600;
  }

  div[class*='_linkDialogAnchor'] {
    @apply !h-0 !w-[40px];
  }

  a[class*='_linkDialogPreviewAnchor'] {
    @apply flex flex-row gap-1;
  }

  div[class*='_selectWithLabel'] label:first-child {
    @apply hidden;
  }

  button[class*='_selectTrigger'] {
    @apply flex flex-row gap-2 items-center border border-solid border-input max-h-6 rounded-sm px-2 dark:bg-grayDark-800 dark:border-grayDark-500 dark:text-whiteAlpha-900;
  }

  div[class*='_codeMirrorToolbar'] {
    display: none !important
  }

  div[class*='cm-gutters']>div[class*='cm-foldGutter'] {
    display: none !important
  }

  div[class*='cm-scroller'] {
    @apply bg-[#eceff4] rounded-md
  }

  .all-initial li[role='checkbox']::marker {
    content: '✓';
    background: red !important;
  }

  .all-initial li::marker,
  .all-initial li>span {
    @apply dark:text-whiteAlpha-900;
  }

  /* For some unknown reason mdx-editor has on some of its elements a translate y style for 2 px? */
  button[data-toolbar-item='true'] span div {
    transform: translateY(0) !important;
  }

  .tiptap-prose ul,
  .tiptap-prose ol {
    padding-left: 1rem;
  }


  /* mdx editor styles end */
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* vizzly hide chatIdColumn (should be kept as last column) */
.chat-sessions .vizzly-data-table tr td:last-child,
.chat-sessions .vizzly-data-table tr th:last-child {
  @apply hidden;
}

.chat-sessions div[data-component='cellContainer'] {
  width: auto !important;
}

.chat-sessions .vizzly-data-table table {
  width: 100% !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
